var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "about" },
    [
      _c("h1", { staticClass: "text--white" }, [
        _vm._v("Plaid OAuth Account Integration")
      ]),
      _c(
        "b-overlay",
        [
          _c("PlaidLink", {
            attrs: {
              "company-id": _vm.companyId,
              "button-title": "Add a New Bank Account"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }